export const environment = {
  production: true,
  version: '1.0.0-beta.8',
  domain: 'https://develop.patient.sympliact.com',
  environment: 'develop',
  sentry: {
    dns: 'https://d7becbb22f854f6cb767bea3ca101107@o1130118.ingest.sentry.io/6174278',
    traceSampleRate: 1.0
  },
  auth0: {
    client_id: '75Dj07JoLgYEvmLHCAqsTo6BKZ7ZNybA',
    domain: 'sympliact-pat-dev.auth0.com',
    redirect_uri: 'https://develop.patient.sympliact.com/callback',
    logout_url: 'https://develop.patient.sympliact.com/auth/login',
    audience: 'https://sympliact-pat-dev.auth0.com/api/v2/',
    scope: 'openid'
  },
  domainUrl: 'https://develop.api.sympliact.com',
  baseUrl: 'https://develop.api.sympliact.com/api/v1/pat'
};
